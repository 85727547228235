// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import "amazon-connect-streams";
import React, { useCallback, useEffect, useState } from "react";
import { useConnected, useDestroy } from "../hooks";
import { genLogger, valueToOption } from "../lib";
import { buildGetIntentsURL } from "../config";
import { Soluto_URL } from "../config";
import mdnICON from "../../src/assets/mdn.svg";
import pinICON from "../../src/assets/pin.svg";
import subscriberICON from "../../src/assets/subscriber.svg";
import phoneICON from "../../src/assets/phone.svg";
import outgoing_phoneICON from "../../src/assets/outgoing_phone.svg";
import mdnok from "../../src/assets/mdnok.svg";
import pinNoteok from "../../src/assets/pinNotok.svg";
import {
  useAfterCallWork,
  useOnCallAccepted,
  useOnCallIncomming,
} from "../hooks";
import { common } from "../local/common";
import Modal from "react-modal";

const stylesEle = {
  renderdiv: {
    padding: "10px",
    fontSize: "14px",
    borderBottom: "2px solid grey",
  },
  container: {
    flex: 1,

    border: "none",
    // backgroundColor: "white",
    height: "100%",
    minHeight: "99vh",
    borderTop: "2px solid #A4A4A2",

    //  boxShadow: "10px #D5D7D1",
  },
  headerdiv: {
    backgroundColor: "#4a4a4a",
    padding: "9px",
    color: "white",
    fontSize: "14px",
    flex: 1,
    width: "100%",
    marginLeft: "2px",
  },
  headercontainer: {
    display: "flex",
    flex: 1,
  },
  renderLable: {
    flex: 0.5,
    justifyContent: "flex-start",
    fontSize: "30px",
    borderBottom: "2px solid grey",
  },
  renderValue: {
    flex: 0.5,
    justifyContent: "flex-start",
    fontSize: "30px",
    borderBottom: "2px solid grey",
  },
  alertheader: {
    backgroundColor: "blue",
    textAlign: "center",
    padding: "10px",
    color: "white",
    fontSize: "18px",
    marginTop: "25px",
  },
  alertContainer: {
    padding: "10px",
    fontSize: "14px",

    paddingTop: "25px",

    flex: 1,
  },
  customerHeader: {
    diplay: "flex",
    flexDirection: "row",
    flex: 1,
    margin: "10px",
  },
  customerHeaderInside: {
    display: "flex",
    flex: 1,
  },
  customerContainer: {
    flex: 0.4,
    alignContent: "center",
    fontSize: "14px",
    display: "flex",
    flexDirection: "row",
  },
  customerContainerInside: {
    flex: 0.5,
    fontSize: "14px",
  },
  customerContainerBody: {
    height: "2px",
    backgroundColor: "#f0ede6",
    marginTop: "10px",
    marginBottom: "10px",
  },
  contentDialog: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "20%",
  },
  styleLable: {
    alignSelf: "center",
    marginLeft: "8px",
  },
};

const name = "CustomerInfo";
const { log, error } = genLogger(name);

const formatData = (attr = {}) => {
  attr.mdn = attr.MDN || {};
  attr.subscribername = attr.CustomerName || {};
  attr.pin = attr.CustomerPin || {};
  attr.callingNo = attr?.CustomerNumber || {};
  attr.Queue = attr?.Queue || {};
  attr.mdnok = attr?.MDNVerificationResult || {};
  attr.pinok = attr?.PINVerificationResult || {};

  const mdn = attr.mdn?.value;
  const subscribername = attr.subscribername?.value;
  const pin = attr.pin?.value;
  const Queue = attr.Queue?.value;
  const callingNo = attr.callingNo?.value;
  const talkscript = attr.TalkScript?.value;
  const pinok = attr.PINVerificationResult?.value;
  const mdnok = attr.MDNVerificationResult?.value;

  return {
    mdn,
    subscribername,
    pin,
    callingNo,
    Queue,
    talkscript,
    mdnok,
    pinok,
  };
};

const keyNameMappings = {
  mdn: "MDN",
  pin: "PIN ",
  subscribername: "加入者名",
  callingNo: "発信者",
  Queue: "Queue",
};
const keyIconMappings = {
  mdn: <img src={mdnICON}></img>,
  pin: <img src={pinICON}></img>,
  subscribername: <img src={subscriberICON}></img>,
  callingNo: <img src={phoneICON}></img>,
  Queue: <img src={outgoing_phoneICON}></img>,
};

const getIntents = (queueName) => {
  const intentUrl = buildGetIntentsURL(queueName);
  return fetch(intentUrl).then((res) => res.json());
};

const CustomerInfo = () => {
  const [initialIntent, setInitialIntent] = useState({});
  const [selectedIntent, setSelectedIntent] = useState({});
  const [callIntents, setCallIntents] = useState([]);
  const [data, setData] = useState({
    mdn: "-",
    subscribername: "-",
    pin: "-",
    callingNo: "-",
    Queue: "-",
    mdnok: "-",
    pinok: "-",
  });

  const onConnected = useCallback((c) => {
    try {
      const attr = c.getAttributes();
      if (attr === null) {
        throw new Error("attr was null");
      }
      const d = formatData(attr);

      console.log("Data from Smart Routing", attr);
      if (Object.keys(d).length > 0) {
        setData(d);
        const { intent } = d;
        window.open(Soluto_URL);
        setInitialIntent(valueToOption(intent));
      }
    } catch (e) {
      error("couldn't set data", e);
    }
  }, []);

  useOnCallIncomming(onConnected);
  const onAfterCallWork = useCallback((agent) => {
    const status = agent.getStatus().name;
    console.log(
      "[agent.onRefresh] Agent data refreshed. Agent status is " + status
    );

    //  const afterCallWork = agent.getAgentStates()[0];
    agent.setState(
      agent.getAgentStates(),

      {
        success: function () {
          console.log(" Agent is now after call work status");
        },
        failure: function (err) {
          console.log(
            "Couldn't change after call work status. Maybe already in another call?"
          );
        },
      },
      setTimeout(0),
      { enqueueNextState: true }
    );
  });
  useAfterCallWork(onAfterCallWork);
  useEffect(() => {
    let isCancelled = false;
    const asyncFunc = async () => {
      try {
        if (data?.queueName && data?.queueName !== "-") {
          const intents = await getIntents(data?.queueName);
          const formattedIntents = intents
            .map(({ title }) => valueToOption(title))
            .sort((a, b) => a.label.localeCompare(b.label));

          setCallIntents(formattedIntents);
        }
      } catch (e) {
        if (!isCancelled) error(e);
      }
    };
    asyncFunc();

    return () => (isCancelled = true);
  }, [data]);
  const renderFormattedScript = (script, lable) => {
    const OKOKScript = lable === "OKOkcase" ? true : false;
    if (OKOKScript) {
      return script.replace("[ContractName]", data.subscribername);
    } else if (!OKOKScript && data.mdn) {
      return script.replace("[Entered MDN]", data.mdn);
    } else {
      return script;
    }
  };

  const renderImgValue = (ele) => {
    const { lable, value } = ele;

    const renderImg = ["MDN", "PIN "].includes(lable);

    if (renderImg) {
      if (
        (data?.mdnok == "true" && lable == "MDN") ||
        (data?.pinok == "true" && lable == "PIN ")
      ) {
        return <img src={mdnok} />;
      } else if (
        (data?.mdnok == "false" && lable == "MDN") ||
        (data?.pinok == "false" && lable == "PIN ")
      ) {
        return <img src={pinNoteok} />;
      } else {
        return "-";
      }
    } else {
      return <div style={stylesEle.customerContainerInside}>{value}</div>;
    }
  };

  const formatKeyAndValue = () => {
    let dataRes = [];

    for (let key in keyNameMappings) {
      let obj = {
        lable: keyNameMappings[key],
        value: data[key],
        img: keyIconMappings[key],
      };
      dataRes.push(obj);
    }

    return (
      <div style={stylesEle.customerHeader}>
        {dataRes.map((ele, id) => {
          return (
            <>
              <div style={stylesEle.customerHeaderInside}>
                <div style={stylesEle.customerContainer}>
                  {ele.img}
                  <div style={stylesEle.styleLable}>{ele.lable}</div>
                </div>
                <div style={stylesEle.customerContainerInside}>
                  {renderImgValue(ele)}
                </div>
              </div>
              {dataRes.length - 1 > id && (
                <div style={stylesEle.customerContainerBody}></div>
              )}
            </>
          );
        })}
      </div>
    );
  };

  const onDestroy = useCallback((c) => {
    try {
      log("destroyed, emptying data");
      setData({
        MDN: "-",
        pin: "-",
        Queue: "-",
        callingNo: "-",
        subscribername: "-",
        TalkScript: "-",
        pinok: "-",
      });
      setSelectedIntent({});
      setInitialIntent({});
    } catch (e) {
      error("couldn't empty data", e);
    }
  }, []);
  useDestroy(onDestroy);

  const [dialogOpen, setDialogOpen] = useState(false);

  function afterOpenModal() {}

  function closeModal() {
    setDialogOpen(false);
  }
  return (
    <>
      <div style={stylesEle.container}>
        <select style={stylesEle.headerdiv} onClick={() => setDialogOpen(true)}>
          <id value=""> Sub Status</id>
          <option value="someOption"> Sub Status</option>
        </select>

        <div style={stylesEle.headercontainer}>{formatKeyAndValue()}</div>
        <div style={stylesEle.alertContainer}>
          {data.pinok == "-"
            ? "-"
            : data.pinok
            ? renderFormattedScript(common.OKOKcase, "OKOkcase")
            : renderFormattedScript(common.OKNGcase, "OKNGcase")}
        </div>
        <div>
          <Modal
            isOpen={dialogOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={stylesEle.contentDialog}
            contentLabel="Example Modal"
          >
            <h2>Hello</h2>
            <button onClick={closeModal}>close</button>
            <div></div>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default CustomerInfo;
