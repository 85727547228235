/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: MIT-0
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy of this
 * software and associated documentation files (the "Software"), to deal in the Software
 * without restriction, including without limitation the rights to use, copy, modify,
 * merge, publish, distribute, sublicense, and/or sell copies of the Software, and to
 * permit persons to whom the Software is furnished to do so.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED,
 * INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
 * PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT
 * HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION
 * OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE
 * SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

let connectName = "uap-acyan-sqa-jpmob-telephony";
let apiGatewayID =
  "e5uu6jl9kd-vpce-0a572bd2315bcbdba.execute-api.ap-northeast-1.amazonaws.com";
let cfDistributionUrl = "https://d2yl9yg0gzk9v0.cloudfront.net";

// set default values for dev
export const CONNECT_NAME = connectName.includes("<")
  ? "uap-acyan-sqa-jpmob-telephony"
  : connectName;
export const API_GATEWAY_ID = apiGatewayID.includes("<")
  ? "e5uu6jl9kd-vpce-0a572bd2315bcbdba.execute-api.ap-northeast-1.amazonaws.com"
  : apiGatewayID;
export const CF_DISTRIBUTION_URL = cfDistributionUrl.includes("<")
  ? "https://d2yl9yg0gzk9v0.cloudfront.net"
  : cfDistributionUrl;

export const API_HOST = `https://${API_GATEWAY_ID}.execute-api.ap-northeast-1.amazonaws.com/AcyanSQA4`;
export const POST_CALL_INTENT_URL = `${API_HOST}/postNewCallIntent`;
export const buildIntentsForAgentURL = (agentName) => {
  return `${API_HOST}/getIntentsForAgent?agentName=${agentName}`;
};
export const UPDATE_RECORDING_STATUS_URL = `${API_HOST}/updateRecordingStatus`;

export const buildGetIntentsURL = (queueName) =>
  `${API_HOST}/getCallIntents/${queueName}`;
export const POST_NEW_CONTACT_ID_URL = ({ agentName, id }) =>
  `${API_HOST}/postNewContactId?agentName=${agentName}&connectID=${id}`;
export const Soluto_URL = `https://acyan-sqa4.uap.jpnmob-acyan.npr.aws.asurion.net/replacement`;
